const youtubePopup = () => {
  const popupsLinks = document.querySelectorAll(
    '[klickart-edit-link-type="popup"]'
  );
  const youtubeEmbedRegex = /youtube\.com\/embed/;
  const validYoutubeIframe = (iframe) => {
    return youtubeEmbedRegex.test(iframe.getAttribute("data-src"));
  };

  [...popupsLinks].forEach((popupLink) => {
    popupLink.addEventListener("click", function () {
      const popupId = popupLink.getAttribute("href").replace("#", "");
      const popup = document.getElementById(popupId);
      const popupIframes = [...popup.querySelectorAll("iframe")];

      popupIframes.forEach((iframe) => {
        if (!validYoutubeIframe(iframe)) {
          return;
        }
        const youtubeSrc = iframe.getAttribute("data-src");
        iframe.removeAttribute("data-src");
        iframe.setAttribute("src", youtubeSrc);
      });
    });
  });
};

export default youtubePopup;
